import { Routes } from '@angular/router';

import PageNotFoundComponent from './views/page-not-found.component';
import { authGuard, noAuthGuard } from '@shared/guards';

export const routes: Routes = [
  {
    path: 'public',
    canMatch: [() => noAuthGuard()],
    loadChildren: () => import('./views/public/public.routes'),
  },
  {
    path: 'admin',
    canMatch: [() => authGuard()],
    loadChildren: () => import('./views/protected/protected.routes'),
  },
  {
    path: '',
    redirectTo: '/public/login',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];
