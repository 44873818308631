import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { AuthGateway } from '@core/ports/auth.gateway';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthGateway);

  let newReq = req.clone();

  if (authService.accessToken) {
    newReq = req.clone({
      headers: req.headers.append(
        'Authorization',
        `Bearer ${authService.accessToken}`
      ),
    });

    return next(newReq);
  }

  return next(req);
};
