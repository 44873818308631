import { catchError, Observable, tap, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { Auth, CreateUser } from '@core/models/auth.model';
import { AuthGateway } from '@core/ports/auth.gateway';
import { ACCESS_TOKEN_KEY, CONNECTED_USER_KEY } from '@shared/constants';

export class HttpAuthGateway extends AuthGateway {
  private _httpClient = inject(HttpClient);
  private router = inject(Router);
  private hasTokenExistMsg =
    'Impossible de se connecter. Veuillez vérifier les informations saisies.';
  private _apiUrl = `${environment.apiUrl}/auth`;

  login(credentials: CreateUser): Observable<Auth> {
    return this._httpClient
      .post<Auth>(`${this._apiUrl}/login`, credentials)
      .pipe(
        catchError((err) => throwError(() => err)),
        tap((response: any) => {
          if (response) {
            const accessToken = response?.token?.access_token;
            const user = response?.user_info;

            if (!accessToken) {
              alert(this.hasTokenExistMsg);
              return;
            }

            this.setItemToLocalStorage(accessToken, user);
          }
        })
      );
  }
  logout() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(CONNECTED_USER_KEY);
    return this.router.navigate(['/public/login']);
  }
  getChecksIfUserAlreadyExists(): Observable<any> {
    return this._httpClient
      .get(`${this._apiUrl}/users/check-primary-user`)
      .pipe(catchError((err) => throwError(() => err)));
  }

  get isLoggedIn(): boolean {
    const accesToken = localStorage.getItem(ACCESS_TOKEN_KEY) as string;
    return accesToken !== null;
  }

  get accessToken(): string {
    return localStorage.getItem(ACCESS_TOKEN_KEY) ?? '';
  }
  get connectedUser(): any {
    return JSON.parse(localStorage.getItem(CONNECTED_USER_KEY) as string) ?? '';
  }

  private setItemToLocalStorage(token: any, user: any) {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
    localStorage.setItem(CONNECTED_USER_KEY, JSON.stringify(user));
  }
}
