import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';

import { AreasGateway } from '@core/ports';
import { environment } from 'src/environments/environment';

export class HttpAreasGateway extends AreasGateway {
  private _httpClient = inject(HttpClient);
  private router = inject(Router);
  private _apiUrl = `${environment.apiUrl}/params`;

  getAreas(): Observable<any> {
    return this._httpClient
      .get(`${this._apiUrl}/area`)
      .pipe(catchError((err) => this.handleError(err)));
  }

  getLocalitiesByArea(area: string | null): Observable<any> {
    return this._httpClient
      .get(`${this._apiUrl}/area/${area}`)
      .pipe(catchError((err) => this.handleError(err)));
  }

  private handleError(error: Error) {
    console.error('[] error', error);
    return throwError(() => error);
  }
}
