import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { Provider, EnvironmentProviders } from '@angular/core';

import { HttpAuthGateway } from '@core/adapters';
import { AuthGateway } from '@core/ports';
import { authInterceptor, errorInterceptor } from '@shared/interceptors';

export const provideAuth = (): Array<Provider | EnvironmentProviders> => {
  return [
    provideHttpClient(withInterceptors([authInterceptor, errorInterceptor])),
    {
      provide: AuthGateway,
      useClass: HttpAuthGateway,
    },
  ];
};
