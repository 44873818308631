import { Provider, EnvironmentProviders } from '@angular/core';

import {
  HttpAreasGateway,
  HttpBilanGateway,
  HttpCreditScoringGateway,
  HttpKybGateway,
  HttpKycGateway,
  HttpSfsGateway,
} from '@core/adapters';
import {
  AreasGateway,
  BilanGateway,
  CreditScoringGateway,
  KybGateway,
  KycGateway,
  SfsGateway,
} from '@core/ports';

export const provideGatewayService = (): Array<
  Provider | EnvironmentProviders
> => {
  return [
    {
      provide: AreasGateway,
      useClass: HttpAreasGateway,
    },
    {
      provide: BilanGateway,
      useClass: HttpBilanGateway,
    },
    {
      provide: KybGateway,
      useClass: HttpKybGateway,
    },
    {
      provide: KycGateway,
      useClass: HttpKycGateway,
    },
    {
      provide: SfsGateway,
      useClass: HttpSfsGateway,
    },
    {
      provide: CreditScoringGateway,
      useClass: HttpCreditScoringGateway,
    },
  ];
};
