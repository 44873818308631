import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

import { KybGateway } from '@core/ports';
import { environment } from 'src/environments/environment';

export class HttpKybGateway extends KybGateway {
  private _httpClient = inject(HttpClient);
  private _apiUrl = `${environment.apiUrl}/kyb`;

  getPingKyb(): Observable<any> {
    return this._httpClient
      .get(`${this._apiUrl}/@ping`)
      .pipe(catchError((err) => this.handleError(err, null)));
  }
  getKybs(params: Record<string, number>): Observable<any> {
    return this._httpClient
      .get(`${this._apiUrl}`, { params })
      .pipe(catchError((err) => this.handleError(err, [])));
  }
  getKybByKycId(params: Record<string, number>): Observable<any> {
    return this._httpClient
      .get(`${this._apiUrl}`, { params })
      .pipe(catchError((err) => this.handleError(err, [])));
  }
  getKyb(kycId: string): Observable<any> {
    return this._httpClient
      .get(`${this._apiUrl}/${kycId}`)
      .pipe(catchError((err) => this.handleError(err, null)));
  }
  postFindKyb(
    data: Record<any, any>,
    params: Record<string, number>
  ): Observable<any> {
    return this._httpClient
      .post(`${this._apiUrl}/@find`, data, {
        params,
      })
      .pipe(catchError((err) => this.handleError(err, [])));
  }
  postCreateKyb(content: Record<any, any>, options?: any): Observable<any> {
    return this._httpClient
      .post(`${this._apiUrl}/@create`, content, this.httpOptions(options))
      .pipe(catchError((err) => this.handleError(err, null)));
  }
  putKyb(kycId: string, content: Record<any, any>): Observable<any> {
    return this._httpClient
      .put(`${this._apiUrl}/${kycId}`, content)
      .pipe(catchError((err) => this.handleError(err, null)));
  }
  patchKyb(kybId: string, content: Record<any, any>): Observable<any> {
    return this._httpClient
      .patch(`${this._apiUrl}/${kybId}`, content)
      .pipe(catchError((err) => this.handleError(err, null)));
  }
  deleteKyb(kycId: string): Observable<any> {
    return this._httpClient
      .delete(`${this._apiUrl}/${kycId}`)
      .pipe(catchError((err) => this.handleError(err, null)));
  }

  private handleError(error: Error, errorValue: any) {
    console.error('[] error', error);
    return throwError(() => error);
  }

  private httpOptions(option?: any) {
    const options = {
      params: { id_kyc: option?.id_kyc },
    };

    return options;
  }
}
