import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';

import { AuthGateway } from '@core/ports/auth.gateway';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthGateway);

  return next(req).pipe(
    catchError((error: unknown) => {
      if (error instanceof HttpErrorResponse) {
        const { status } = error;
        if (status === 401 || status === 403) {
          authService.logout();
        }
      }
      return throwError(() => error);
    })
  );
};
