import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';

import { BilanGateway } from '@core/ports';
import { environment } from 'src/environments/environment';

export class HttpBilanGateway extends BilanGateway {
  private _httpClient = inject(HttpClient);
  private _apiUrl = `${environment.apiUrl}/bilan`;

  getPingBilan(): Observable<null> {
    return this._httpClient
      .get(`${this._apiUrl}/@ping`)
      .pipe(catchError((err) => this.handleError(err, null)));
  }
  getBilans(): Observable<any> {
    return this._httpClient
      .get(`${this._apiUrl}`)
      .pipe(catchError((err) => this.handleError(err, null)));
  }
  getBilan(bilanId: string): Observable<any> {
    return this._httpClient
      .get(`${this._apiUrl}/${bilanId}`)
      .pipe(catchError((err) => this.handleError(err, null)));
  }
  postFindBilan(
    content: Record<any, any>,
    params: Record<string, number>
  ): Observable<any> {
    const { limit, page } = params;
    return this._httpClient
      .post(`${this._apiUrl}/@find`, content, {
        params: { page, limit },
      })
      .pipe(catchError((err) => this.handleError(err, [])));
  }
  postBilan(content: Record<any, any>): Observable<any> {
    return this._httpClient
      .post(`${this._apiUrl}/@create`, content)
      .pipe(catchError((err) => this.handleError(err, null)));
  }
  patchBilan(bilanId: string, content: Record<any, any>): Observable<any> {
    return this._httpClient
      .patch(`${this._apiUrl}/${bilanId}`, content)
      .pipe(catchError((err) => this.handleError(err, null)));
  }
  deleteBilan(bilanId: string): Observable<any> {
    return this._httpClient
      .delete(`${this._apiUrl}/${bilanId}`)
      .pipe(catchError((err) => this.handleError(err, null)));
  }

  private handleError(error: Error, errorValue: any) {
    console.error('[] error', error);
    return of(errorValue);
  }
}
