import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

import { SfsGateway } from '@core/ports';
import { environment } from 'src/environments/environment';

export class HttpSfsGateway extends SfsGateway {
  private _httpClient = inject(HttpClient);
  private _apiUrl = `${environment.apiUrl}/sfs`;
  readonly bucketPrefix = 'upload';

  getBuckets(): Observable<any> {
    return this._httpClient
      .get(`${this._apiUrl}`)
      .pipe(catchError((err) => this.handleError(err, [])));
  }
  getBuckectFiles(moduleName: string): Observable<any> {
    return this._httpClient
      .get(`${this._apiUrl}/${this.generateBucketName(moduleName)}`)
      .pipe(catchError((err) => this.handleError(err, [])));
  }
  getFileByBucket(fileName: string, moduleName: string): Observable<any> {
    return this._httpClient
      .get(
        `${this._apiUrl}/${this.generateBucketName(moduleName)}/${fileName}`,
        { responseType: 'blob' }
      )
      .pipe(catchError((err) => this.handleError(err, null)));
  }
  postBucket(moduleName: string): Observable<any> {
    return this._httpClient
      .post(`${this._apiUrl}/${this.generateBucketName(moduleName)}`, {})
      .pipe(catchError((err) => this.handleError(err, null)));
  }
  putFile(file: any, moduleName: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this._httpClient
      .put(`${this._apiUrl}/${this.generateBucketName(moduleName)}`, formData)
      .pipe(catchError((err) => this.handleError(err, null)));
  }
  deleteFile(fileName: string, moduleName: string) {
    return this._httpClient
      .delete(
        `${this._apiUrl}/${this.generateBucketName(moduleName)}/${fileName}`
      )
      .pipe(catchError((err) => this.handleError(err, null)));
  }

  private handleError(error: Error, errorValue: any) {
    console.error('[] error', error);
    return throwError(() => error);
  }

  generateBucketName(moduleName: string) {
    return `${this.bucketPrefix}-${moduleName}-files`;
  }
}
