import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';

import { CreditScoringGateway } from '@core/ports';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

export class HttpCreditScoringGateway extends CreditScoringGateway {
  private _httpClient = inject(HttpClient);
  private _apiUrl = `${environment.apiUrl}/csr`;

  getPingCsr(): Observable<any> {
    return this._httpClient
      .get(`${this._apiUrl}/@ping`)
      .pipe(catchError((err) => this.handleError(err)));
  }
  getCsrs(): Observable<any> {
    return this._httpClient
      .get(`${this._apiUrl}`)
      .pipe(catchError((err) => this.handleError(err)));
  }
  getCsr(csrID: string): Observable<any> {
    return this._httpClient
      .get(`${this._apiUrl}/${csrID}`)
      .pipe(catchError((err) => this.handleError(err)));
  }
  postFindCsr(
    data: Record<any, any>,
    params: Record<string, any>
  ): Observable<any> {
    return this._httpClient
      .post(`${this._apiUrl}/@find`, data, {
        params,
      })
      .pipe(catchError((err) => this.handleError(err)));
  }
  postCreateCsr(data: Record<any, any>): Observable<any> {
    return this._httpClient
      .post(`${this._apiUrl}/@evaluate`, data)
      .pipe(catchError((err) => this.handleError(err)));
  }
  deleteCsr(csrID: string): Observable<any> {
    return this._httpClient
      .delete(`${this._apiUrl}/${csrID}`)
      .pipe(catchError((err) => this.handleError(err)));
  }

  private handleError(error: Error) {
    return throwError(() => error);
  }
}
